.kama {
  height: 99vh;
  width: 100vw;
  padding: 20px;
  font-size: 18px;
  box-sizing: border-box;
  color: rgb(255, 144, 0);
  user-select: none;
  -webkit-user-drag: none;
  header {
    text-align: center;
  }
  header > p {
    font-size: 20px;
    color: rgb(235, 84, 117);
    text-shadow: 1px 1px 4px rgb(255, 144, 0), 0 0 1em rgb(226, 243, 152),
      0 0 0.2em rgb(230, 108, 26);
    text-align: left;
  }
  header span {
    margin-right: 10px;
    /* color: #000; */
    /* font-weight: bold; */
  }
  .body {
    height: 60vh;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 0.71s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  footer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .change {
    width: 70%;
    height: 50px;
    font-size: 18px;
    line-height: 50px;
    color: #fff;
    border-radius: 20px;
    background: rgb(255, 144, 0);
    border: 2px solid #fff;
    text-align: center;
  }
  .change:active {
    background: rgb(177, 110, 22);
  }
}
.kama::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent url("./asset/bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(3px) opacity(20%);
  z-index: -1;
}
